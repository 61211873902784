.model {
  position: relative;
  opacity: 0;

  &[data-loaded='true']:global {
    animation: fadeIn 1s ease forwards var(--delay);
  }
}

.canvas {
  position: absolute;
  inset: 0;
}
